import { Col, Row } from 'antd'
import { OldRow } from 'components/Row'
import { LoadingItem } from 'components/Skeleton'
import React from 'react'

export const LoadingWorkspaceDesktop: React.FC = () => {
  return (
    <>
      <OldRow gutter={40}>
        <Col flex="88px">
          <LoadingItem width="48px" height="48px" borderRadius="360px" />
        </Col>
        <Col flex="1">
          <OldRow gutter={[0, 48]}>
            <Col sm={8}>
              <Row>
                <LoadingItem width="40px" height="8px" />
              </Row>
              <Row style={{ marginTop: 16 }}>
                <LoadingItem width="144px" height="8px" />
              </Row>
            </Col>
            <Col sm={8}>
              <Row>
                <LoadingItem width="80px" height="8px" />
              </Row>
              <Row style={{ marginTop: 16 }}>
                <LoadingItem width="32px" height="8px" />
              </Row>
            </Col>
            <Col sm={8}>
              <Row>
                <LoadingItem width="72px" height="8px" />
              </Row>
              <Row style={{ marginTop: 16 }}>
                <LoadingItem width="32px" height="8px" />
              </Row>
            </Col>
            <Col sm={8}>
              <Row>
                <LoadingItem width="40px" height="8px" />
              </Row>
              <Row style={{ marginTop: 16 }}>
                <LoadingItem width="48px" height="8px" />
              </Row>
            </Col>
            <Col sm={8}>
              <Row>
                <LoadingItem width="56px" height="8px" />
              </Row>
              <Row style={{ marginTop: 16 }}>
                <LoadingItem width="96px" height="8px" />
              </Row>
            </Col>
            <Col sm={8}>
              <Row>
                <LoadingItem width="40px" height="8px" />
              </Row>
              <Row style={{ marginTop: 16 }}>
                <LoadingItem width="96px" height="8px" />
              </Row>
            </Col>
            <Col sm={24}>
              <Row>
                <LoadingItem width="40px" height="8px" />
              </Row>
              <Row style={{ marginTop: 16 }}>
                <LoadingItem width="200px" height="8px" />
              </Row>
            </Col>
          </OldRow>
          <Row>
            <Col sm={24}>
              <Row>
                <LoadingItem width="120px" height="8px" />
              </Row>
            </Col>
          </Row>
        </Col>
      </OldRow>
    </>
  )
}
