import { GetRoles } from 'graphql-shared'
import { Col, Row, Skeleton } from 'antd'
import { BaseCard } from 'components/BaseCard'
import { ButtonLink } from 'components/Button'
import { ImageRounded } from 'components/Image'
import { TextSm, TextSmMinus } from 'components/Typography'
import { GET_ROLES } from 'graphql-shared'
import { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'
import { formatFullAddress, formatPhoneNumber } from 'utils/format'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { OldRow } from 'components/Row'
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingWorkspaceDesktop } from './LoadingWorkspaceDesktop'

export const DesktopWorkspaceDetail: React.FC = () => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  const { user, isLoading } = useAuth0()
  //*todo change to Futuremakers
  const workspaceMember = null
  const [visibleAddtionalDetail, setVisibleAddtionalDetail] = useState(false)

  return (
    <OldRow gutter={[0, 40]}>
      <Col sm={24}>
        <BaseCard paddingAdj="48px 40px">
          {!user || isLoading ? (
            <LoadingWorkspaceDesktop />
          ) : (
            <>
              <OldRow gutter={40}>
                <Col flex="88px">
                  {/* <ImageRounded src={workspaceMember?.workspace?.image} width={48} height={48} alt="company-image-detail" /> */}
                  <ImageRounded
                    src="/static/logo/image-fm-logo.png"
                    width={48}
                    height={48}
                    alt="company-image-detail"
                  />
                </Col>
                <Col flex="1">
                  <OldRow gutter={[0, 32]}>
                    <Col sm={8}>
                      <TextSmMinus color={themeContext.fontColor.medium}>ชื่อบริษัท</TextSmMinus>
                      <TextSm>{workspaceMember?.workspace?.name || '-'} </TextSm>
                    </Col>
                    <Col sm={8}>
                      <TextSmMinus color={themeContext.fontColor.medium}>ประเภทแพ็กเกจ</TextSmMinus>
                      <TextSm>ฟรี</TextSm>
                    </Col>
                    <Col sm={8}>
                      <TextSmMinus color={themeContext.fontColor.medium}>ผู้ใช้งานทั้งหมด</TextSmMinus>
                      <TextSm>{workspaceMember?.workspace?.totalMembers || '-'} </TextSm>
                    </Col>{' '}
                    <Col sm={8}>
                      <TextSmMinus color={themeContext.fontColor.medium}>อีเมล</TextSmMinus>
                      <TextSm>{workspaceMember?.workspace?.email || '-'} </TextSm>
                    </Col>{' '}
                    <Col sm={8}>
                      <TextSmMinus color={themeContext.fontColor.medium}>เบอรติดต่อ</TextSmMinus>
                      <TextSm>{formatPhoneNumber(workspaceMember?.workspace?.phone) || '-'} </TextSm>
                    </Col>{' '}
                    <Col sm={8}>
                      <TextSmMinus color={themeContext.fontColor.medium}>โทรสาร (Fax)</TextSmMinus>
                      <TextSm>{workspaceMember?.workspace?.fax || '-'} </TextSm>
                    </Col>
                    <Col sm={24}>
                      <TextSmMinus color={themeContext.fontColor.medium}>ที่อยู่</TextSmMinus>
                      <TextSm>
                        {formatFullAddress({
                          address: workspaceMember?.workspace?.address,
                          province: workspaceMember?.workspace?.province,
                          district: workspaceMember?.workspace?.district,
                          subDistrict: workspaceMember?.workspace?.subDistrict,
                          zipcode: workspaceMember?.workspace?.zipcode
                        }) || '-'}{' '}
                      </TextSm>
                    </Col>
                  </OldRow>
                  <Row>
                    <Col>
                      <Col sm={24}>
                        <ButtonLink onClick={() => setVisibleAddtionalDetail(!visibleAddtionalDetail)} type="link">
                          {!visibleAddtionalDetail ? (
                            <>
                              <TextSm>
                                แสดงรายละเอียดเพิ่มเติม{' '}
                                <DownOutlined style={{ fontSize: '0.6rem', marginLeft: '8px' }} />
                              </TextSm>
                            </>
                          ) : (
                            <>
                              ซ่อนรายละเอียดเพิ่มเติม <UpOutlined style={{ fontSize: '0.6rem', marginLeft: '8px' }} />
                            </>
                          )}
                        </ButtonLink>
                      </Col>
                    </Col>
                  </Row>
                  {visibleAddtionalDetail && (
                    <Row style={{ marginTop: 40 }}>
                      <Col sm={8}>
                        <TextSmMinus color={themeContext.fontColor.medium}>เลขประจำตัวผู้เสียภาษี</TextSmMinus>
                        <TextSm>{workspaceMember?.workspace?.taxId || '-'}</TextSm>
                      </Col>
                      <Col sm={8}>
                        <TextSmMinus color={themeContext.fontColor.medium}>ชื่อสาขาหลัก</TextSmMinus>
                        <TextSm>{workspaceMember?.workspace?.branchName || '-'}</TextSm>
                      </Col>
                      <Col sm={8}>
                        <TextSmMinus color={themeContext.fontColor.medium}>เลขที่สาขาหลัก</TextSmMinus>
                        <TextSm>{workspaceMember?.workspace?.branchId || '-'}</TextSm>
                      </Col>
                    </Row>
                  )}
                </Col>
              </OldRow>
            </>
          )}
        </BaseCard>
      </Col>
    </OldRow>
  )
}
