import React from 'react'
import { useRouter } from 'next/router'
import { Col } from 'antd'
import { IconButton } from 'components/Button'
import { AntButton } from 'components/Button/ant-button'
import { OldRow } from 'components/Row'
import styled from 'styled-components'
import { PathMenu } from 'utils/constants'

import { EditOutlined, ScanOutlined } from '@ant-design/icons'

const WrapperIcon = styled.div`
  position: absolute;
  top: -120px;
  right: 0;
`
export const DesktopEditButton = () => {
  const router = useRouter()

  return (
    <OldRow justify="space-between" gutter={[0, 20]}>
      <Col>
        <AntButton
          type="primary"
          icon={<ScanOutlined />}
          text="Scan the employee code"
          onClick={() => router.push(PathMenu.FLEET_LIST_MOBILE)}
        />
      </Col>
      <Col>
        <WrapperIcon>
          <IconButton onClick={() => router.push(PathMenu.WORKSPACE_UPDATE)} icon={<EditOutlined />} />
        </WrapperIcon>
      </Col>
    </OldRow>
  )
}
