import { Col, Row } from 'antd'
import { Grid } from 'antd'
const { useBreakpoint } = Grid
import React from 'react'
import { OldRow } from 'components/Row'
import styled from 'styled-components'

interface ILoadingItem {
  width: string
  height: string
  borderRadius?: string
}

export const LoadingItem = styled.div`
  width: ${(props: ILoadingItem) => `${props.width}`};
  height: ${(props: ILoadingItem) => `${props.height}`};
  border-radius: ${(props: ILoadingItem) => props.borderRadius || '8px'};
  background: linear-gradient(270deg, #bdbdbd, #eaeaea);
  background-size: 400% 400%;

  -webkit-animation: LoadingAnimate 1s ease infinite;
  -moz-animation: LoadingAnimate 1s ease infinite;
  animation: LoadingAnimate 1s ease infinite;

  @-webkit-keyframes LoadingAnimate {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes LoadingAnimate {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes LoadingAnimate {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

export const BarChartDataLoading = styled(LoadingItem)`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`
export const LoadingPagination: React.FC = () => {
  const screens = useBreakpoint()

  return (
    <>
      {!screens?.xs ? (
        <Row justify="space-between" align="middle" style={{ marginTop: 40, marginBottom: 16 }}>
          <Col flex="auto">
            <OldRow gutter={24}>
              <Col>
                <LoadingItem width="120px" height="8px" />
              </Col>
              <Col>
                <LoadingItem width="184px" height="8px" />
              </Col>
            </OldRow>
          </Col>
          <Col>
            <LoadingItem width="64px" height="8px" />
          </Col>
        </Row>
      ) : (
        <>
          <Row justify="start" align="middle" style={{ marginTop: 32 }}>
            <LoadingItem width="120px" height="8px" />
          </Row>
          <Row justify="start" align="middle" style={{ marginTop: 16 }}>
            <LoadingItem width="184px" height="8px" />
          </Row>
          <Row justify="start" align="middle" style={{ marginTop: 32, marginBottom: 8 }}>
            <LoadingItem width="64px" height="8px" />
          </Row>
        </>
      )}
    </>
  )
}
