import { ReactNode } from 'react'
import Link from 'next/link'
import { Button } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import cx from 'clsx'
import { AntParagraph, AntText } from 'components/Typography/ant-typography'

import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
/* ------------------------------- Ant Button ------------------------------- */
interface BaseProps {
  type?: 'default' | 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'ghost' | 'text' | 'outline'
  text?: string
  icon?: ReactNode
  htmlType?: 'button' | 'reset' | 'submit'
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLElement> | undefined
  className?: string
  block?: boolean
  size?: SizeType
  loading?: boolean
  shape?: 'circle' | 'round' | 'default'
  suffix?: ReactNode
}
type ConditionalProps = { text: string; icon?: ReactNode } | { text?: string; icon: ReactNode }
export type AntButtonProps = BaseProps & ConditionalProps
export const AntButton: React.FC<AntButtonProps> = ({
  type = 'default',
  text,
  htmlType = 'button',
  disabled = false,
  className,
  block = false,
  onClick,
  size = 'middle',
  loading = false,
  icon,
  shape = 'round',
  suffix,
  ...rest
}) => {
  const getType = (): any => {
    if (type === 'default')
      return {
        type: 'default',
        className: 't-ant-btn-default'
      }
    if (type === 'primary')
      return {
        type: 'primary',
        className: 't-ant-btn-primary'
      }
    if (type === 'danger')
      return {
        type: 'primary',
        danger: true,
        className: 't-ant-btn-dangerous'
      }
    if (type === 'secondary')
      return {
        className: 't-ant-btn-secondary'
      }
    if (type === 'success')
      return {
        className: 't-ant-btn-success'
      }
    if (type === 'warning')
      return {
        className: 't-ant-btn-warning'
      }
    if (type === 'ghost')
      return {
        ghost: true,
        className: 't-ant-btn-ghost'
      }
    if (type === 'text') {
      return {
        type: 'text',
        className: 't-ant-btn'
      }
    }
  }
  return (
    <Button
      {...rest}
      {...getType()}
      onClick={onClick}
      htmlType={htmlType}
      disabled={disabled}
      className={`${getType()?.className ? getType()?.className : ''} ${className}`}
      block={block}
      size={size}
      shape={shape}
      loading={loading}
      icon={icon}
    >
      {text}
      {suffix && suffix}
    </Button>
  )
}

/* ------------------------------- Switch Button ------------------------------- */
interface SwitchButtonProps {
  status?: 'active' | 'inactive' | null
  onToggleStatus?: (status: 'active' | 'inactive') => void
  className?: string
}

export const SwitchButton: React.FC<SwitchButtonProps> = ({ status, onToggleStatus, className }) => {
  const checkedIconStyle = cx('text-[28px]', status === 'active' ? 'text-success' : 'text-success/50')
  const unCheckedIconStyle = cx('text-[28px]', status === 'inactive' ? 'text-danger' : 'text-danger/50')

  return (
    <div className={cx('inline-flex items-start gap-2 rounded-full bg-gray-200/50 p-[2px]', className)}>
      <div
        className="flex justify-center items-center gap-1 rounded-full cursor-pointer"
        role="button"
        onClick={() => onToggleStatus('active')}
      >
        <CheckCircleFilled className={checkedIconStyle} />
      </div>
      <div
        className="flex justify-center items-center gap-1 rounded-full cursor-pointer"
        role="button"
        onClick={() => onToggleStatus('inactive')}
      >
        <CloseCircleFilled className={unCheckedIconStyle} />
      </div>
    </div>
  )
}

/* ------------------------------- Radio Card Button ------------------------------- */

type RadioCardItem = { key: string; label: any; description?: any }
interface RadioCardButtonProps {
  active?: string
  items: RadioCardItem[]
  onChange?: (item: RadioCardItem) => void
  className?: string
  itemClassName?: string
}

export const RadioCardButton: React.FC<RadioCardButtonProps> = ({
  active,
  items,
  onChange,
  className,
  itemClassName
}) => {
  const itemStyle = 'border border-solid border-gray-200 rounded-md p-[8px] cursor-pointer'
  const activeItemStyle = 'border-primary bg-primary/10'

  const activeCircleStyle = 'border-2 border-primary'

  return (
    <div className={cx('flex items-start gap-2', className)}>
      {items?.map((item) => {
        return (
          <div
            key={item.key}
            className={cx(itemStyle, itemClassName, item.key === active && activeItemStyle)}
            role="button"
            onClick={() => onChange(item)}
          >
            <div className="flex items-start gap-2">
              <div
                className={cx(
                  'border w-2 h-2 rounded-xl border-solid border-gray-200',
                  item.key === active && activeCircleStyle
                )}
              />
              <div className="-mt-1">
                <AntText text={item.label} />
                {item?.description && <AntParagraph text={item?.description} type="secondary" />}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

interface CheckboxGroupCardProps {
  items: RadioCardItem[]
  selected?: string[]
  onSelect?: (item: RadioCardItem) => void
  onUnselect?: (item: RadioCardItem) => void
  className?: string
  itemClassName?: string
}

/* ------------------------------- Checkbox Group Card Buttons ------------------------------- */
export const CheckboxGroupCard: React.FC<CheckboxGroupCardProps> = ({
  items,
  selected,
  onSelect,
  onUnselect,
  className,
  itemClassName
}) => {
  const containerStyle = 'flex flex-wrap items-start gap-2'
  const itemStyle = 'border border-solid border-gray-200 rounded-md flex items-start gap-1 p-1 cursor-pointer'

  const activeItemStyle = 'border-primary bg-primary/10'

  return (
    <div className={cx(containerStyle, className)}>
      {items?.map((item) => {
        const isActive = selected?.includes(item?.key)

        return (
          <div
            key={item.key}
            className={cx(itemStyle, itemClassName, isActive && activeItemStyle)}
            role="button"
            onClick={() => {
              if (isActive) onUnselect(item)
              else onSelect(item)
            }}
          >
            <input
              type="checkbox"
              checked={isActive}
              onChange={(e) => {
                if (e?.target?.checked) onSelect(item)
                else onUnselect(item)
              }}
            />
            <AntText text={item.label} />
          </div>
        )
      })}
    </div>
  )
}

/* ------------------------------- Segment Button ------------------------------- */

type SegemntType = {
  label: string
  value: string
}
interface SegmentButtonProps {
  items: SegemntType[]
  active?: string
  onClick?: (item: SegemntType) => void
  className?: string
  itemClassName?: string
}

export const SegmentButton: React.FC<SegmentButtonProps> = ({ items, active, className, itemClassName, onClick }) => {
  const btnClass = 'flex justify-center items-center rounded-full p-2 cursor-pointer transition ease-in-out delay-100'
  const labelClass = 'transition ease-in-out delay-100'

  return (
    <div className={cx('inline-flex items-start space-x-3 rounded-full bg-gray-200/[.30] p-1', className)}>
      {items?.map((eachItem) => (
        <div
          className={cx(btnClass, itemClassName, eachItem?.value === active ? 'bg-white' : 'bg-white/[.30]')}
          key={eachItem.value}
          role="button"
          onClick={() => onClick?.(eachItem)}
        >
          <AntText
            text={eachItem.label}
            className={cx(labelClass, eachItem?.value === active ? 'text-black' : 'text-black/[.30]')}
          />
        </div>
      ))}
    </div>
  )
}

/* ------------------------------- Link Button ------------------------------- */
interface LinkButtonProps {
  href: any
  text: string
  onClick?: () => void
}

export const LinkButton: React.FC<LinkButtonProps> = ({ href, text, onClick }) => {
  return (
    <Link href={href}>
      <AntButton text={text} onClick={onClick} />
    </Link>
  )
}
