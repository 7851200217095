import { Grid } from 'antd'

const { useBreakpoint } = Grid
import { DesktopEditButton } from './components/desktop/EditButton'
import { DesktopWorkspaceDetail } from './components/desktop/WorkspaceDetail'
import { MobileEditButton } from './components/mobile/EditButton'
import { MobileWorkspaceDetail } from './components/mobile/WorkspaceDetail'

export const ShowWorkspace: React.FC = () => {
  const { xs, sm, md, lg } = useBreakpoint()

  return (
    <>
      {(xs || sm || md) && !lg ? <MobileEditButton /> : <DesktopEditButton />}
      {(xs || sm || md) && !lg ? <MobileWorkspaceDetail /> : <DesktopWorkspaceDetail />}
    </>
  )
}
